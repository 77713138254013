<template>
    <div class="grid">
        <div class="col-12">
            <div class="font-medium text-xl text-900 mb-3">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="responsaveis-form" />
                    <h3 class="ml-1">{{ title }}</h3>
                </div>
            </div>
            <div class="text-500 mb-5">Preencha os campos abaixo</div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <h5 class="mb-4">Novo responsável</h5>

                    <Toast />

                    <div class="">
                        <div class="field">
                            <label for="name">Nome</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.name }"
                            />
                            <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                        </div>

                        <div class="field">
                            <label for="type">Tipo</label>
                            <InputText
                                id="name"
                                v-model.trim="form.type"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.type }"
                            />
                            <small class="p-error" v-if="submitted && !form.type">Tipo é obrigatório.</small>
                        </div>
                    </div>

                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/responsible')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            form: {},
            submitted: false,
            loading: false
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new BaseService('/responsibles');
        try {
            if (this.$route.params.id) {
                const { data } = await this.$service.findById(this.$route.params.id);
                this.form = data;
            }
        }
        catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar responsável' : 'Adicionar responsável';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async save() {
            this.submitted = true;
            try {
                await this.$service.save(this.form);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Responsável salvo com sucesso!',
                    life: 3000
                });
                this.$router.replace('list');
            }
            catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o responsável!',
                    life: 3000
                });
            }
        }
    },
    components: { AppInfoManual }
};
</script>

